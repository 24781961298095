import { config } from '../config';
import axios from 'axios';
import { post } from 'axios';


export const userService = {
    login,
    logout,
    Get,
    Post,
    MobilePost,
    PrivateClassRequest
};

function login(data) {
    return axios.post(`${config.apiUrl}User/Login`, data)
        .then(res => res.data)
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('spuser', JSON.stringify(user));
            if (user.token) {
                axios.defaults.headers.common['X-Apikey'] = user.token;
            } else {
                axios.defaults.headers.common['X-Apikey'] = null;
            }
            return user;
        })
        .catch(function (error) {
            console.log(error);
        });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('qiuser');
}

function Get(url) {
    return axios.get(`${config.apiUrl}${url}`)
        .then(res => res.data)
        .then(data => {
            return data;
        });
}

function Post(url, data) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };
    return fetch(`${config.apiUrl}${url}`, requestOptions)
        .then(response => response.json())
        .then(data => {
            return data;
        })
        .catch(function (error) {
            console.log(error);
        });
}

function MobilePost(url, data) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };
    return fetch(`${config.detailApiUrl}${url}`, requestOptions)
        .then(response => response.json())
        .then(data => {
            return data;
        })
        .catch(function (error) {
            console.log(error);
        });
}

function PrivateClassRequest(data) {
    let f = new FormData();
    Object.keys(data).map(function (key) {
        f.append(key, data[key])
    });
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'multipart/form-data' },
        //body: f
    };
    return post(`${config.apiUrl}SpediaAPI/PrivateClassRequest`, f, requestOptions).then(res => res.data)
        .then(res => {
            return res;
        })
}

