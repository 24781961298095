import React from 'react';
import loader from '../images/loader.gif';

const Loader = () => {
    return (
        <div className="loader">
            <div className="loader-inner">
                <div className="pl1">
                    <div className="pl1__a"></div>
                    <div className="pl1__b"></div>
                    <div className="pl1__c"></div>
                </div>
            </div>
        </div>
    );
}

export default Loader;