import { combineReducers } from 'redux';
import { user } from './users.reducer';
import { menu } from './menu.reducer';


const rootReducer = combineReducers({
  user,
  menu
});

export default rootReducer;