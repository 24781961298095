import { userConstants } from '../constants';

const initialState = {

};

export function menu(state = initialState, action) {
    switch (action.type) {        
        default:
            return state
    }
}