import React from 'react';
import { useSelector } from 'react-redux';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import { store } from './store';
//import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import LanguageContextProvider from "./contexts/LanguageContext";
import Loader from './containers/loader';
import './i18n/config';
// import { registerServiceWorker } from './serviceWorker'

const LoadingIndicator = props => {
  const user = useSelector(state => state.user);
  return (
    user.loading ?
      <Loader /> : null
  );
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <LanguageContextProvider>
        <App />
        <LoadingIndicator />
      </LanguageContextProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
//serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
// registerServiceWorker();
