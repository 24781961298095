function getApi() {
    let api = '';
    if (process.env.NODE_ENV === 'development') {
        //api = "https://store.spediaapp.com/api/";
        api = "https://storetestapi.spediaapp.com/api/";
    }
    else {
        //api = "https://storetestapi.spediaapp.com/api/";
        api = "https://store.spediaapp.com/api/";
    }
    return api;
}

function getMobileApi() {
    let api = '';
    if (process.env.NODE_ENV === 'development') {
        // api = "https://newAdmin.spediaapp.com/api/SpediaMobile/";
        api = "https://beta.spediaapp.com/api/SpediaMobile/"
    }
    else {
        //api = "https://beta.spediaapp.com/api/SpediaMobile/"
        api = "https://newAdmin.spediaapp.com/api/SpediaMobile/"

    }
    return api;
}
export const config = {
    apiUrl: getApi(),
    detailApiUrl: getMobileApi()
}