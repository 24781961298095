export const constants={
    REQUEST: 'REQUEST',
    SET_COUNTRY_CODE:'SET_COUNTRY_CODE',
    SET_COUNTRY_LIST:'SET_COUNTRY_LIST',
    SET_CATEGORY_LIST:'SET_CATEGORY_LIST',
    SET_CARTCOUNT:'SET_CARTCOUNT',
    SET_CARTARRAY:'SET_CARTARRAY',
    REMOVE_ITEM_CARTARRAY:'REMOVE_ITEM_CARTARRAY',
    SET_CARTDATA:'SET_CARTDATA',
    SET_COUNTRY_WARNING:'SET_COUNTRY_WARNING',
    GOTO_SERVICE_DETAIL:'GOTO_SERVICE_DETAIL',
    SET_LANGUAGE:'SET_LANGUAGE'
}